import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import HeaderCv from "components/Headers/HeaderCV.js";

const CarteVitale = () => {
    // Simuler des données utilisateur pour la démonstration
    const userData = {
        nom: "Dupont",
        prenom: "Jean",
        dateNaissance: "01/01/1980",
        numeroSecuriteSociale: "123456789012345",
        adresse: "123 Rue de Paris, 75000 Paris"
    };

    // Fonction pour gérer l'enregistrement des données
    const handleSave = async () => {
        try {
            const response = await fetch('/api/clients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });
            if (response.ok) {
                console.log("Données enregistrées avec succès");
            } else {
                throw new Error('Erreur lors de l\'enregistrement des données');
            }
        } catch (error) {
            console.error('Erreur:', error);
        }
    };

    return (
        <>
            <HeaderCv />
            <Container className="mt-5">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <CardTitle tag="h5">Informations de la Carte Vitale</CardTitle>
                                    <Button
                                        color="primary"
                                        onClick={handleSave}
                                        style={{
                                            background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
                                            color: 'white',
                                            border: 'none',
                                            marginBottom: '20px'  // Ajout d'une marge au bas du bouton
                                        }}>
                                        Enregistrer Client
                                    </Button>
                                </div>
                                <ListGroup>
                                    <ListGroupItem>Nom: {userData.nom}</ListGroupItem>
                                    <ListGroupItem>Prénom: {userData.prenom}</ListGroupItem>
                                    <ListGroupItem>Date de Naissance: {userData.dateNaissance}</ListGroupItem>
                                    <ListGroupItem>Numéro de Sécurité Sociale: {userData.numeroSecuriteSociale}</ListGroupItem>
                                    <ListGroupItem>Adresse: {userData.adresse}</ListGroupItem>
                                </ListGroup>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CarteVitale;
